@import "../../../../styles/media-mixin";

.exit-to-lobby {
  @include media(M) {
    max-width: 644px;
  }
  &__header {
    font-size: 28px;
    line-height: normal;
    color: #cd3334;
    @include media(M) {
      font-size: 34px;
    }
  }
  &__highlighted {
    color: #fff;
  }
  &__continue {
    display: block;
    margin: 15px auto;
    padding: 9px 24px;
    line-height: normal;
  }
  &__exit {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 18px;
    color: var(--color-danger);
    &:hover {
      text-decoration: underline;
      color: var(--color-danger);
    }
  }
  &__games {
    position: relative;
    min-height: 220px;
    padding: 12px 30px 15px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--color-light-black);
    @include media(M) {
      padding-right: 10px;
      padding-bottom: 35px;
      padding-left: 10px;
    }
  }
  &__games-title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #faff00;
    @include media(M) {
      margin-bottom: 30px;
    }
  }
  &__games-grid {
    flex-direction: column;
    align-items: center;
    @include media(M) {
      flex-direction: row;
    }
  }
  &__game {
    max-width: 220px;
    height: 150px;
    @include media(XL) {
      height: 120px !important;
      margin-bottom: 0 !important;
    }
  }
}
