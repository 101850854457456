@import "../../../../styles/media-mixin";

.left-sidebar {
  &__title {
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 700;
    color: var(--color-warning);
  }
  .sign-up-form__already-reg {
    margin-bottom: 15px;
    text-align: center;
  }
  .sign-up-form__already-regLink {
    display: block;
    margin-left: 0;
  }
  &__guest-tablet-row {
    flex-direction: column;
    align-items: center;
    @include media(M) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__sign-up-btn {
    height: 43px;
    margin-right: 12px;
    padding-right: 15px;
    padding-left: 15px;
    line-height: 32px;
  }
  &__sign-in-btn {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.left-sidebar-logged {
  display: flex;
  flex-direction: column;
  color: var(--color-light-grey);
  @include media(M) {
    flex-direction: row;
    align-items: center;
  }
  @include media(XL) {
    flex-direction: column;
    align-items: initial;
  }
  &__player {
    max-width: 160px;
    margin-bottom: 10px;
    font-weight: 700;
    @include media(M) {
      margin-bottom: 0;
    }
    &:hover {
      color: #ccc;
    }
  }
  &__icon {
    margin-right: 10px;
    font-size: 28px;
    vertical-align: middle;
  }
  &__bonus-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    @include media(M) {
      margin-bottom: 0;
      margin-left: 15px;
    }
    @include media(XL) {
      flex-direction: column;
      align-items: initial;
      margin-left: 0;
    }
  }
  &__bonus-img {
    max-width: 55px;
    margin-right: 10px;
    @include media(XL) {
      max-width: 100%;
      margin-right: 0;
    }
  }
  &__free-spin {
    font-size: 15px;
    color: #fff;
    @include media(XL) {
      font-size: 16px;
    }
  }
  &__bonus {
    @include media(XL) {
      max-width: 100%;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    @include media(M) {
      margin-left: auto;
    }
    @include media(XL) {
      flex-direction: column;
      margin-left: 0;
    }
  }
  &__deposit-btn {
    flex: 1;
    margin-right: 10px;
    padding: 8px 26px;
    @include media(M) {
      flex: auto;
    }
    @include media(XL) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &__presents-btn {
    flex: 1;
    padding: 7px 26px;
    @include media(M) {
      flex: auto;
    }
    @include media(XL) {
      width: 100%;
    }
  }
}
