.winners-vertical {
  height: 460px;
  &__item {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    padding: 10px 15px;
  }
  .slick-active .winners-vertical__item {
    animation: tada 1s both 1s;
  }
}
