@import "../../styles/media-mixin";

.modal {
  &-dialog {
    @include media(M) {
      max-width: 620px;
    }
  }
  &-backdrop {
    background-color: #000;
    &.show {
      opacity: 0.9;
    }
  }
  &-content {
    border-radius: 0;
    background-color: var(--color-grey);
  }
  &-header {
    position: relative;
    justify-content: center;
    padding: 35px 45px 17px;
    border-bottom: 0;
    .close {
      position: absolute;
      top: 22px;
      right: 15px;
      z-index: 1;
      font-size: 18px;
      text-shadow: none;
      opacity: 1;
      color: #7c7c7c;
      &:hover {
        color: #ebc120;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    color: var(--color-accent);
  }
  &-body {
    padding: 17px 15px 35px;
  }
}
