@import "../../styles/media-mixin";

.footer {
  flex: 0 0 auto;
  padding: 20px 25px;
  @include media(M) {
    padding-bottom: 100px;
  }
  &__sprites {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &__sprite {
    display: block;
    flex: 0 0 auto;
    margin: 12px;
    &:not(:hover) {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
}
