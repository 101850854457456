@import "../../styles/media-mixin";

.back-to-top {
  position: fixed;
  bottom: 45px;
  left: 15px;
  z-index: 1;
  transform: translate3d(-250%, 0, 0);
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-accent);
  font-size: 14px;
  line-height: normal;
  color: var(--color-accent);
  transition: all 0.175s linear;
  @include media(M) {
    bottom: 100px;
    left: 25px;
  }
  @include media(XL) {
    left: 50px;
  }
  &:hover {
    background-color: var(--color-accent);
    color: #2c2c2c;
  }
  &.isVisible {
    transform: translate3d(0, 0, 0);
  }
}
