@import "../styles/media-mixin";

.maintenance {
  max-width: 620px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 15px 20px;
  text-align: center;
  @include media(M) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
  }
  &__title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 28px;
    line-height: normal;
    @include media(M) {
      margin-bottom: 30px;
      font-size: 36px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: normal;
  }
  &__text-highlighted {
    color: var(--color-accent);
  }
  &__content {
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  &__icon {
    font-size: 100px;
    color: var(--color-accent);
  }
  &__form {
    max-width: 280px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
  &__footer {
    color: #636363;
  }
}
