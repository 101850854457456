.leaderboard-pagination {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  &__item {
    display: inline-block;
    padding-right: 2px;
    padding-left: 2px;
    cursor: pointer;
    color: rgba(255, 191, 25, 0.5);
    &:nth-child(n+2) {
      margin-left: 6px;
    }
    &:hover,
    &.active {
      color: var(--color-warning);
    }
  }
}
