@import "../../styles/media-mixin";

.not-found {
  align-items: center;
  @include media(M) {
    min-height: 100vh;
  }
  @include media(MOBILE) {
    text-align: center;
  }
  &__number-text {
    text-align: center;
    font-size: 180px;
    color: var(--color-accent);
    @include media(MOBILE) {
      font-size: 100px;
    }
  }
  &__text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
