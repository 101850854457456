@import "../../styles/media-mixin";

.banner {
  position: relative;
  min-height: 140px;
  @include media(M) {
    min-height: 310px;
    margin-top: -46px;
  }
  &__content {
    position: relative;
    min-height: 140px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include media(M) {
      min-height: 310px;
    }
  }
  &__timer {
    position: absolute;
    bottom: 35px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    min-width: 175px;
    padding: 7px 10px;
    background-color: rgba(35, 35, 35, 0.7);
    color: #fff;
    @include media(M) {
      bottom: 50px;
    }
    .timer__label {
      margin-bottom: 0;
      text-transform: none;
    }
  }
}
