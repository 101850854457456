@import "../../styles/media-mixin";

.navigation {
  z-index: 1;
  display: none;
  flex: 0 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  background-color: var(--color-grey);
  @include media(M) {
    display: flex;
    justify-content: space-around;
  }
  @include media(L) {
    justify-content: center;
  }
}
