@import "../../../styles/media-mixin";

.calendar {
  max-width: 725px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  &__title {
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0 4px 8px #3f3f3f;
    @include media(S) {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
  &__label {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    color: var(--color-accent);
    @include media(S) {
      margin-bottom: 20px;
    }
  }
  &__description {
    margin-bottom: 20px;
    text-align: center;
    line-height: normal;
    @include media(S) {
      margin-bottom: 35px;
      text-align: left;
    }
  }
  &__heading {
    position: relative;
    margin-bottom: 6px;
    padding: 10px 15px;
    background-color: var(--color-dark-grey);
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    @include media(S) {
      padding-top: 17px;
      padding-bottom: 17px;
    }
    &::before,
    &::after {
      content: url("/img/calendar-page-heading.svg");
      position: absolute;
      top: -7px;
    }
    &::before {
      left: 18px;
      @include media(S) {
        left: 38px;
      }
    }
    &::after {
      right: 18px;
      @include media(S) {
        right: 38px;
      }
    }
  }
  &__item {
    margin-right: 3px;
    padding: 20px 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: var(--color-dark-grey);
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    color: var(--color-light-grey);
    &.current {
      border-color: #ebc120;
      background-color: #ebc120;
      color: #fff;
    }
    &:hover {
      border-color: var(--color-accent);
      cursor: pointer;
    }
  }
  &__events {
    margin-top: 20px;
  }
  &__event {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 270px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 15px 10px;
    border: 2px solid #1f1f1f;
    text-align: center;
    line-height: normal;
    transition: border 0.2s ease-in-out;
    cursor: pointer;
    @include media(M) {
      max-width: 100%;
      min-height: 100%;
    }
    &:hover {
      border-color: var(--color-danger);
    }
  }
  &__event.active {
    border-color: var(--color-danger);
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      cursor: default;
    }
    &::before {
      bottom: -24px;
      border-right: 10px solid transparent;
      border-bottom: 9px solid #b52f30;
      border-left: 10px solid transparent;
    }
    &::after {
      bottom: -24px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom-width: 6px;
      border-bottom-style: solid;
      border-bottom-color: var(--color-light-black);
    }
  }
  &__event-image {
    max-width: 100%;
    height: auto;
    max-height: 134px;
    margin-bottom: 12px;
    .finished & {
      filter: grayscale(100%);
    }
  }
  &__event-title {
    text-transform: uppercase;
    font-weight: 700;
  }
  &__event-label {
    color: var(--color-accent);
    .finished & {
      color: var(--color-light-grey);
    }
  }
  &__event-prize {
    margin-top: -5px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    color: var(--color-accent);
    .finished & {
      color: var(--color-light-grey);
    }
  }
  &__event-time {
    margin-bottom: auto;
  }
  &__event-info {
    max-width: 270px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding: 18px 10px;
    border: 2px solid var(--color-danger);
    line-height: normal;
    @include media(M) {
      max-width: 100%;
    }
  }
  &__event-info-title {
    margin-bottom: 3px;
    text-transform: uppercase;
  }
  &__event-info-btn {
    display: inline-block;
    margin-top: 15px;
    font-size: 18px;
    text-decoration: underline;
    color: #00c2ff;
  }
  &__timer-wrapper {
    text-align: center;
    @include media(M) {
      margin-top: 20px;
    }
  }
  &__timer {
    display: inline-block;
    min-width: 200px;
    padding: 7px 15px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-accent);
    background-color: var(--color-dark-grey);
    text-transform: none;
    line-height: normal;
    color: #fff;
  }
  &__deposit-btn {
    padding: 11px 35px;
  }
  &__no-events {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
    padding: 30px 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0 4px 8px #3f3f3f;
    @include media(S) {
      font-size: 24px;
    }
    @include media(M) {
      height: 240px;
      font-size: 30px;
    }
  }
  &__event-btn {
    margin-top: 15px;
  }
  .slick-list::before,
  .slick-list::after {
    @media only screen and (max-width: 430px) {
      content: "";
      position: absolute;
      top: 0;
      z-index: 1;
      display: block;
      width: 50px;
      height: 100%;
    }
  }
  .slick-list::before {
    @media only screen and (max-width: 430px) {
      left: 0;
      background-image: linear-gradient(90deg, #0f0f0f, rgba(15, 15, 15, 0));
    }
  }
  .slick-list::after {
    @media only screen and (max-width: 430px) {
      right: 0;
      background-image: linear-gradient(90deg, rgba(15, 15, 15, 0), #0f0f0f);
    }
  }
  .slick-current &__item {
    border-color: var(--color-danger);
  }
}

.calendar-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 46px;
  line-height: normal;
  color: var(--color-light-grey);
  transition: color 0.2s ease-in-out;
  @include media(S) {
    &:hover {
      color: var(--color-light-grey);
    }
  }
  &--left {
    left: 0;
    @include media(S) {
      left: -25px;
    }
  }
  &--right {
    right: 0;
    @include media(S) {
      right: -25px;
    }
  }
}
