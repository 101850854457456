@import "../../../styles/media-mixin";

.form-group {
  position: relative;
  padding-top: 20px;
  .form-group {
    padding-top: 0;
  }
  .field-label {
    font-size: 13px;
    line-height: 16px;
    color: var(--color-light-grey);
  }
}

.form-control {
  height: 40px;
  padding-top: 7px;
  padding-right: 30px;
  padding-bottom: 7px;
  border-radius: 0;
  border-color: var(--color-light-grey);
  background-color: var(--color-grey);
  box-shadow: none;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  &:focus {
    border-color: #fff;
    background-color: var(--color-grey);
    box-shadow: none;
    color: #fff;
  }
  &:disabled {
    border-color: #555;
    background-color: var(--color-dark-grey) !important;
    color: #555;
    pointer-events: none;
  }
  &.valid:not(.no-icons),
  &.completed {
    background: var(--color-grey) url("/img/themes/dark/value-valid.svg") no-repeat calc(100% - 10px) 15px;
  }
  &:not(:focus).has-danger {
    border-color: var(--color-danger);
    background: var(--color-grey) url("/img/value-invalid.svg") no-repeat calc(100% - 10px) 12px;
    &.no-icons {
      background: none;
    }
  }
}

.form-group:not(.form-row) .field-label {
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 12px;
  padding-left: 12px;
}

.form-group .form-control + .field-label {
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 0;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  text-align: left;
  transition: all 200ms;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  will-change: transform;
}

.form-group .form-control:focus + .field-label,
.form-group .form-control.valid + .field-label,
.form-group .form-control.dirty + .field-label {
  transform: translate3d(0, -205%, 0);
  font-size: 75%;
}

.input-group-append .btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
