.slider-arrows {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  display: inline-block;
  background-color: #444;
  &:hover {
    background-color: rgba(68, 68, 68, 0.6);
  }
  &__content {
    display: inline-block;
    padding: 23px 8px;
    font-size: 34px;
    color: #fff;
  }
  &--left {
    left: 0;
    border-top-right-radius: 180px;
    border-bottom-right-radius: 180px;
  }
  &--left &__content {
    padding-right: 15px;
  }
  &--right {
    right: 0;
    border-top-left-radius: 180px;
    border-bottom-left-radius: 180px;
  }
  &--right &__content {
    padding-left: 15px;
  }
}
