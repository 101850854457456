.radio-icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  border-color: #fff;
  background-color: var(--color-grey);
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: var(--color-accent);
  }
  &--big {
    width: 24px;
    height: 24px;
    &::before {
      width: 18px;
      height: 18px;
    }
  }
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  &-label {
    font-size: 13px;
    line-height: 16px;
    color: var(--color-light-grey);
  }
  &:hover {
    cursor: pointer;
    .radio-icon::before {
      content: "";
    }
    .radio-label {
      color: var(--color-accent);
    }
  }
  input[type="radio"] {
    display: none;
    &:checked ~ .radio-label {
      color: #fff;
    }
    &:checked ~ .radio-icon::before {
      content: "";
    }
  }
}

.form-check-inline:last-child {
  margin-right: 0;
}
