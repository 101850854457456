@import "../../styles/media-mixin";

.event-item {
  margin-top: 20px;
  margin-bottom: 20px;
  @include media(L) {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__icon {
    display: block;
    text-align: center;
    font-size: 52px;
    text-shadow: 0 4px 8px #000;
    color: #fff;
  }
  &__title {
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0 4px 8px #000;
    color: #fff;
  }
  &__image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }
  &__overlay {
    margin-bottom: -2px;
    padding: 25px 5px 17px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: linear-gradient(180deg, rgba(23, 23, 23, 0), rgba(0, 0, 0, 0.8) 61.6%);
    text-align: center;
    text-shadow: 0 4px 8px #000;
    color: var(--color-accent);
  }
  &__prizePoolTitle {
    line-height: normal;
  }
  &__prizePool {
    margin-top: -7px;
    font-size: 46px;
    font-weight: 700;
    line-height: normal;
  }
  &__subtitle {
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    color: var(--color-accent);
  }
  &__timer {
    margin-top: 20px;
    margin-bottom: 15px;
    @include media(L) {
      margin-top: 50px;
    }
  }
  &__action {
    text-align: center;
  }
  &__actionBtn {
    padding: 10px 24px;
  }
  &__slider {
    margin-top: 7px;
  }
  &__paragraph {
    margin-bottom: 5px;
    text-align: center;
    color: #fff;
  }
  &__slider-content {
    text-align: center;
    .img-fluid {
      width: 73px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__slider-label {
    margin-top: 6px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: #fff;
  }
  &__prize-count {
    width: 1px;
    color: var(--color-accent);
  }
}
