.toggler {
  padding: 0;
  border: 0;
  span {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--color-accent);
    transition: all 0.3s ease-in-out;
    &:nth-child(2) {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  &.activated {
    span:nth-child(1) {
      transform: translateY(16px) rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translateY(-16px) rotate(-45deg);
    }
  }
}
