.winner-item {
  display: flex;
  align-items: flex-start;
  &__image {
    flex: 0 0 50px;
    max-width: 50px;
    height: 50px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &__content {
    flex: 1;
    margin-left: 10px;
    font-size: 13px;
    line-height: normal;
    color: var(--color-light-grey);
  }
  &__amount {
    font-weight: 700;
    color: var(--color-accent);
  }
  &__game-name {
    text-decoration: underline;
    color: #ccc;
    &:hover {
      text-decoration: underline;
      color: #ccc;
    }
  }
}
