@import "../../../styles/media-mixin";

.game {
  flex: 1 0 100%;
  background-image: url("/img/themes/dark/game-bg.png");
  background-repeat: repeat;
  background-size: 340px 340px;
  @include media(M) {
    padding: 10px 15px 30px;
  }
  @include media(XL) {
    padding-right: 30px;
    padding-left: 30px;
  }
  &__logo {
    display: block;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    text-align: center;
    @include media(XL) {
      margin-bottom: 10px;
    }
  }
  &__back-btn {
    margin-top: 10px;
    color: var(--color-light-grey);
    &:hover {
      color: #ccc;
    }
  }
  &__add-to-fav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 20px;
    color: var(--color-light-grey);
    @include media(M) {
      justify-content: flex-start;
      margin-bottom: 0;
      &:hover {
        color: #ebc120;
      }
    }
    &.active {
      color: var(--color-accent);
    }
  }
  &__add-to-fav-icon {
    margin-right: 10px;
    font-size: 28px;
  }
  &__add-to-fav-text {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__actions {
    flex-direction: column-reverse;
    text-align: center;
    @include media(M) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
