.success-block {
  padding-top: 10px;
  text-align: center;
  &__icon {
    font-size: 75px;
    color: var(--color-success);
  }
  &__text {
    margin-top: 30px;
    margin-bottom: 55px;
    color: var(--color-success);
  }
  &__action {
    padding: 12px 70px;
  }
}
