.sprite {
  display: block;
  background-image: url("/img/sprite.svg");
  background-repeat: no-repeat;
  &-qiwi {
    width: 90px;
    height: 40px;
    background-position: -6px -7px;
  }
  &-yamoney {
    width: 76px;
    height: 23px;
    background-position: -131px -51px;
  }
  &-playson {
    width: 112px;
    height: 24px;
    background-position: -6px -116px;
  }
  &-amaticdirect {
    width: 85px;
    height: 24px;
    background-position: -531px -150px;
  }
  &-betsoft {
    width: 74px;
    height: 29px;
    background-position: -740px -58px;
  }
  &-endorphina {
    width: 82px;
    height: 37px;
    background-position: -269px -141px;
  }
  &-hab {
    width: 113px;
    height: 23px;
    background-position: -124px -116px;
  }
  &-qs {
    width: 104px;
    height: 25px;
    background-position: -433px -57px;
  }
  &-microgaming {
    width: 104px;
    height: 26px;
    background-position: -541px -57px;
  }
  &-netent {
    width: 89px;
    height: 32px;
    background-position: -649px -57px;
  }
  &-18plus {
    width: 67px;
    height: 64px;
    background-position: -366px -128px;
  }
  &-curacao {
    width: 68px;
    height: 62px;
    background-position: -448px -129px;
  }
  &-card {
    width: 123px;
    height: 28px;
    background-position: -6px -51px;
  }
  &-perfectmoney {
    width: 116px;
    height: 24px;
    background-position: -197px -12px;
  }
  &-payeer {
    width: 104px;
    height: 24px;
    background-position: -702px -12px;
  }
  &-beeline {
    width: 111px;
    height: 36px;
    background-position: -7px -246px;
  }
  &-tele2 {
    width: 103px;
    height: 38px;
    background-position: -228px -47px;
  }
  &-megafon {
    width: 126px;
    height: 23px;
    background-position: -322px -12px;
  }
  &-mts {
    width: 78px;
    height: 37px;
    background-position: -350px -57px;
  }
  &-skrill {
    width: 80px;
    height: 26px;
    background-position: -617px -12px;
  }
  &-neteller {
    width: 116px;
    height: 20px;
    background-position: -490px -16px;
  }
  &-sofort {
    width: 104px;
    height: 19px;
    background-position: -129px -141px;
  }
  &-piastrix {
    width: 104px;
    height: 42px;
    background-position: -7px -166px;
  }
  &-b2crypto {
    width: 132px;
    height: 23px;
    background-position: -112px -166px;
  }
  &-jeton_wallet {
    width: 75px;
    height: 23px;
    background-position: -541px -87px;
  }
  &-ecopayz {
    width: 99px;
    height: 24px;
    background-position: -621px -93px;
  }
  &-mir {
    width: 106px;
    height: 30px;
    background-position: -621px -118px;
  }
  &-accent-gaming {
    width: 130px;
    height: 20px;
    background-position: -474px -281px;
  }
  &-b2binpay {
    width: 139px;
    height: 32px;
    background-position: -234px -194px;
  }
  &-rapidtransfer {
    width: 114px;
    height: 34px;
    background-position: -119px -225px;
  }
  &-paysafecard {
    width: 110px;
    height: 19px;
    background-position: -114px -283px;
  }
  &-nordea {
    width: 106px;
    height: 23px;
    background-position: -7px -283px;
  }
  &-cap_bank {
    width: 85px;
    height: 30px;
    background-position: -730px -124px;
  }
  &-qr {
    width: 97px;
    height: 29px;
    background-position: -623px -270px;
  }
  &-community_bank {
    width: 75px;
    height: 30px;
    background-position: -540px -206px;
  }
  &-paygiga {
    width: 111px;
    height: 35px;
    background-position: -623px -190px;
  }
  &-easyeft {
    width: 108px;
    height: 24px;
    background-position: -233px -233px;
  }
  &-astropay {
    width: 72px;
    height: 49px;
    background-position: -745px -207px;
  }
  &-webmoney {
    width: 99px;
    height: 25px;
    background-position: -242px -288px;
  }
  &-zimpler {
    width: 102px;
    height: 28px;
    background-position: -8px -314px;
  }
  &-mastercard {
    width: 85px;
    height: 61px;
    background-position: -94px -352px;
  }
}
