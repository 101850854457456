@import "../../styles/media-mixin";

.active-bonus-progress-bar {
  max-width: 200px;
  &__status {
    max-width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-light-grey);
    overflow: hidden;
    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }
  &__progress {
    height: 7px;
    border-radius: 4px;
    background-color: #303030;
    &:hover {
      cursor: pointer;
    }
  }
  &__progress-bar {
    border-radius: 4px;
    background-color: var(--color-accent);
  }
  &__tooltip-content {
    max-width: 230px;
    margin-top: -4px;
    padding: 5px 10px;
    border: 2px solid var(--color-accent);
    border-radius: 4px;
    background-color: var(--color-light-black);
    text-align: left;
    font-size: 12px;
    color: var(--color-accent);
  }
  &__tooltip.show {
    opacity: 1;
  }
}

.bs-tooltip-bottom {
  top: 5px !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-bottom .arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
}

.bs-tooltip-bottom .arrow::before {
  top: -7px;
  border-right: 13px solid transparent;
  border-bottom: 11px solid var(--color-accent);
  border-left: 13px solid transparent;
}

.bs-tooltip-bottom .arrow::after {
  top: -4px;
  border-right: 11px solid transparent;
  border-bottom: 9px solid var(--color-light-black);
  border-left: 11px solid transparent;
}
