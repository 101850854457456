@import "../../styles/media-mixin";

.post-registration {
  padding-top: 0;
  padding-bottom: 10px;
  background-image: linear-gradient(180deg, #333, #000);
  &__info {
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
    text-align: center;
  }
  &__title {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #fff;
  }
  &__description {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
  }
  &__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 253px;
    max-width: 100%;
    height: 253px;
    margin: 5px auto;
  }
  &__button {
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__link {
    display: inline-block;
    margin-top: 7px;
    text-decoration: underline;
    color: var(--color-warning);
    &:hover {
      color: #ffa030;
    }
  }
  &__dots {
    margin: 10px 0 0;
    padding: 0;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 10px;
      border: 1px solid #666;
      border-radius: 50%;
      vertical-align: middle;
      @include media(M) {
        margin-right: 20px;
        margin-left: 20px;
      }
      &.slick-active {
        border-color: var(--color-warning);
        background-color: var(--color-warning);
      }
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
      }
    }
  }
}
