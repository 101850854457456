.mobile-menu {
  &__item {
    margin-top: 5px;
    margin-bottom: 5px;
    color: #c4c4c4;
  }
  &__logout {
    font-weight: 700;
    color: #c4c4c4;
  }
  &__icon {
    position: relative;
    top: 4px;
    margin-right: 8px;
    font-size: 24px;
  }
  &__bonus {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  &__bonus-image {
    width: 73px;
    height: auto;
    margin-right: 5px;
  }
  &__divider {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid rgba(196, 196, 196, 0.3);
  }
}
