@import "./media-mixin";

:root {
  --color-accent: #fdd22f;
  --color-grey: #242424;
  --color-light-grey: #999;
  --color-dark-grey: #1c1c1c;
  --color-light-black: #131313;
  --color-success: #45d245;
  --color-danger: #b40707;
  --color-warning: #ffbf19;
}

* {
  min-width: 0;
  min-height: 0;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  background-color: var(--color-grey);
  font-family: "Montserrat", "Proxima Nova", "Armitage", sans-serif;
  font-weight: 400;
  color: #fff;
  &.modal-open {
    position: fixed;
    width: 100%;
    @include media(M) {
      position: relative;
    }
  }
}

#root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

a {
  color: inherit;
  &::-moz-focus-inner {
    border: 0;
  }
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}

ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

button {
  padding: 0;
  border: 0;
  background: none;
  &::-moz-focus-inner {
    border: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.container {
  padding-right: 10px;
  padding-left: 10px;
  @include media(M) {
    max-width: 758px;
  }
  @include media(L) {
    max-width: 992px;
  }
  @include media(XL) {
    max-width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
}

.link {
  font-size: 17px;
  &:focus,
  &:hover,
  &.active {
    color: var(--color-accent);
  }
}

.highlighted {
  color: var(--color-warning);
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--color-warning);
  text-shadow: none;
  &__label {
    position: relative;
    top: 2px;
    font-size: 12px;
    color: var(--color-grey);
  }
  &--animated {
    animation: ring 3s 0.7s linear infinite;
  }
}

.btn .badge {
  position: absolute;
  top: -10px;
}

.alert {
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  color: #cc3d3d;
}

.alert-warning {
  border-radius: 0;
  border-color: var(--color-danger);
  background-color: #3b1717;
  color: var(--color-danger);
}

.is-highlighted {
  color: var(--color-warning);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--color-grey) !important;
  -webkit-box-shadow: 0 0 0 30px inset #1f1f1f !important;
  color: #fff !important;
  transition: none !important;
  -webkit-text-fill-color: #fff;
  & + .field-label {
    transform: translate3d(0, -205%, 0);
    font-size: 75%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes ring {
  0% {
    transform: rotate(0) scale(1.18);
  }
  1% {
    transform: rotate(30deg) scale(1.18);
  }
  3% {
    transform: rotate(-28deg) scale(1.18);
  }
  5% {
    transform: rotate(34deg) scale(1.18);
  }
  7% {
    transform: rotate(-32deg) scale(1.18);
  }
  9% {
    transform: rotate(30deg) scale(1.18);
  }
  11% {
    transform: rotate(-28deg) scale(1.18);
  }
  13% {
    transform: rotate(26deg) scale(1.18);
  }
  15% {
    transform: rotate(-24deg) scale(1.18);
  }
  17% {
    transform: rotate(22deg) scale(1.18);
  }
  19% {
    transform: rotate(-20deg) scale(1.18);
  }
  21% {
    transform: rotate(18deg) scale(1.18);
  }
  23% {
    transform: rotate(-16deg) scale(1.18);
  }
  25% {
    transform: rotate(14deg) scale(1.18);
  }
  27% {
    transform: rotate(-12deg) scale(1.18);
  }
  29% {
    transform: rotate(10deg) scale(1.18);
  }
  31% {
    transform: rotate(-8deg) scale(1.18);
  }
  33% {
    transform: rotate(6deg) scale(1.18);
  }
  35% {
    transform: rotate(-4deg) scale(1.18);
  }
  37% {
    transform: rotate(2deg) scale(1.18);
  }
  39% {
    transform: rotate(-1deg) scale(1.18);
  }
  41% {
    transform: rotate(1deg) scale(1.18);
  }
  43% {
    transform: rotate(0) scale(1);
  }
  100% {
    transform: rotate(0);
  }
}
