.image-container {
  position: relative;
  z-index: 0;
  img {
    max-width: 80px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .preloader__dots {
    display: none;
  }
}
