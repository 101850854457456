.custom-table {
  table {
    margin-bottom: 0;
  }
  td,
  th {
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0;
  }
  thead th {
    border-bottom: 0;
    background-color: rgba(54, 54, 54, 0.9);
    font-weight: 700;
  }
  tbody tr:not(:last-of-type) {
    border-bottom: 1px solid var(--color-light-grey);
  }
  .active {
    color: var(--color-accent);
  }
}
