.right-sidebar {
  &__nav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  &__nav-item {
    flex: 0 0 34px;
    font-size: 34px;
    color: var(--color-light-grey);
    &:hover {
      color: var(--color-accent);
    }
    &.active {
      color: var(--color-warning);
    }
  }
  &__title {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    line-height: normal;
    color: var(--color-accent);
  }
  &__event-title {
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__event-prize {
    font-size: 30px;
  }
  &__event-label {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  &__event-table table td,
  &__event-table table th {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &__event-action {
    margin-top: 20px;
  }
}
