@import "../../../styles/media-mixin";

.header {
  display: block;
  flex: 0 0 auto;
  padding: 15px 0;
  background-color: var(--color-light-black);
  @include media(M) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  > .container {
    padding-right: 10px;
    padding-left: 10px;
  }
  &__logo {
    display: flex;
    text-align: center;
    @include media(M) {
      text-align: left;
    }
  }
  &__language {
    width: 40px;
    text-align: center;
    @include media(M) {
      order: 4;
      width: auto;
    }
    &.logged {
      display: block;
      @include media(M) {
        display: none;
      }
    }
  }
}

.guest-header {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-top: 20px;
  @include media(M) {
    flex: 0 0 auto;
    margin-top: 0;
    margin-right: 35px;
    margin-left: auto;
  }
  @include media(XL) {
    margin-right: 25px;
  }
  &__sign-up {
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      flex: 3 0 auto;
    }
  }
  &__sign-in {
    padding: 10px;
    @media screen and (max-width: 767px) {
      flex: 1 0 auto;
    }
    @include media(M) {
      margin-right: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
    @include media(XL) {
      margin-right: 50px;
    }
  }
}

.logged-header {
  flex: 1 0 auto;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-light-grey);
  &__username {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    @include media(XL) {
      margin-right: 32px;
      margin-left: 52px;
    }
    &:hover {
      color: #ccc;
    }
  }
  &__balance {
    display: inline-block;
    @include media(S) {
      &:hover {
        color: #ccc;
      }
    }
  }
  &__icon {
    position: relative;
    display: inline-block;
    font-size: 22px;
    vertical-align: middle;
    @include media(M) {
      margin-right: 10px;
    }
  }
  &__props {
    display: inline-block;
    max-width: 80px;
    margin-left: 7px;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    color: #fff;
    overflow: hidden;
  }
  &__active-bonus {
    margin-left: 20px;
    @include media(XL) {
      margin-left: 40px;
    }
  }
  &__cashbox {
    margin-right: 8px;
    margin-left: auto;
    padding-right: 26px;
    padding-left: 26px;
  }
  &__presents {
    margin-right: 20px;
    padding: 10px 14px;
    @include media(XL) {
      margin-right: 35px;
    }
  }
  &__logout {
    &.is-disabled {
      pointer-events: none;
    }
    &:not(.is-disabled):hover {
      color: #ccc;
    }
  }
}

.logged-header-actions {
  margin-top: 15px;
  font-weight: 700;
  &__item {
    padding-top: 3px;
    padding-bottom: 6px;
    background-color: var(--color-grey);
    text-align: center;
    color: var(--color-light-grey);
    &:not(:first-child) {
      border-left: 1px solid #000;
    }
  }
  &__active-bonus {
    display: flex;
    justify-content: center;
  }
  &__presents {
    position: relative;
    .logged-header__icon {
      margin-right: 8px;
    }
    .badge {
      right: 0;
    }
  }
  &__cashbox {
    margin-top: 12px;
    &-wrapper {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;
      border: 1px solid var(--color-accent);
    }
    &-balance {
      margin-right: auto;
      padding-right: 15px;
      padding-left: 15px;
      color: var(--color-light-grey);
    }
    &-btn {
      padding: 9px 30px;
      border: 0;
    }
  }
}
