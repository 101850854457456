@import "../../../styles/media-mixin";

.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  color: #696969;
  @include media(L) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
  }
}
