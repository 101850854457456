@import "../../styles/media-mixin";

.navigation-item {
  padding-top: 9px;
  padding-bottom: 16px;
  font-size: 17px;
  &:nth-child(n+2) {
    @include media(L) {
      margin-left: 50px;
    }
    @include media(XL) {
      margin-left: 80px;
    }
  }
  &:focus,
  &:hover,
  &.active {
    color: var(--color-accent);
  }
  &.active {
    padding-bottom: 12px;
    border-bottom: 4px solid currentColor;
  }
}
