.logo {
  max-width: 200px;
  &__content {
    cursor: pointer;
  }
  &__link {
    display: inline-block;
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}
