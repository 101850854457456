@import "../../../styles/media-mixin";

.field-error {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: var(--color-danger);
  @include media(M) {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
  }
}
