@use "sass:math";

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey);
  &__dots {
    margin-top: 25px;
  }
  &__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: var(--color-accent);
    vertical-align: top;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: pulse 1s infinite calc(1s / 5 * #{$i});
      }
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
