.media-icons {
  display: flex;
  align-items: center;
  color: var(--color-light-grey);
  &__label {
    margin-right: 7px;
    font-weight: 700;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 7px;
    border: 1px solid var(--color-light-grey);
    border-radius: 50%;
    transition: 0.1s;
    &:hover {
      border-color: var(--color-accent);
      color: var(--color-accent);
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      border-color: var(--color-warning);
      text-shadow: 0 0 4px #f00;
      color: var(--color-warning);
      pointer-events: none;
    }
  }
  &.vertical {
    flex-wrap: wrap;
    justify-content: center;
  }
  &.vertical &__label {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .icon-auth-vk,
  .icon-auth-go {
    font-size: 16px;
  }
  .icon-auth-tw,
  .icon-auth-te {
    font-size: 20px;
  }
  .icon-auth-fa {
    font-size: 22px;
  }
  .icon-auth-od {
    font-size: 24px;
  }
}
