@import "../../../../styles/media-mixin";

.sign-up-form {
  max-width: 320px;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-light-grey);
  @include media(M) {
    padding-right: 20px;
    padding-left: 20px;
  }
  &__button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &__already-regLink {
    display: inline-block;
    margin-left: 5px;
    color: var(--color-accent);
    &:hover {
      color: #ebc120;
    }
  }
  &__social {
    margin-top: 35px;
    &-label {
      font-size: 16px;
      line-height: 22px;
      color: #fff;
    }
  }
  &__tooltip-wrapper {
    position: relative;
    width: 100%;
    height: 1px;
  }
  &__alert {
    width: 92%;
    margin-top: -20px;
    margin-right: auto;
    margin-left: auto;
  }
}

.sign-up-form__currency {
  .Select-value {
    text-align: left;
  }
  .Select-menu-outer {
    background-color: #1f1f1f;
  }
  .Select-option {
    text-align: left;
  }
  .is-selected {
    color: var(--color-accent) !important;
  }
  .is-focused {
    color: inherit;
  }
}

.sign-up-form__currency .Select-option:hover {
  background-color: transparent;
  color: var(--color-light-grey);
  @include media(M) {
    color: var(--color-accent);
  }
}
