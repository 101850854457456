@import "../../styles/media-mixin";

.table-load-more {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    width: 0;
    height: 100%;
    box-shadow: 0 0 30px 30px var(--color-light-black);
    @include media(S) {
      content: none;
    }
  }
  &::before {
    left: -10px;
  }
  &::after {
    right: -10px;
  }
  th {
    padding-left: 25px;
    @include media(S) {
      padding-left: 10px;
    }
  }
  td {
    padding-left: 25px;
    white-space: nowrap;
    @include media(S) {
      padding-left: 10px;
      white-space: normal;
    }
  }
  tr td:first-child {
    width: 1px;
  }
  &__action {
    color: #5e481c;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: #916b1d;
    }
  }
  &__action-icon {
    transform: rotate(90deg);
    font-weight: 700;
  }
  &__not-qualified {
    margin-top: 30px;
    text-align: center;
    font-weight: 700;
    color: var(--color-accent);
  }
}
