@use "sass:math";

.short-preloader {
  margin-top: 40px;
  text-align: center;
  &__wrapper {
    width: 100%;
    text-align: center;
  }
  &__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: var(--color-accent);
    vertical-align: top;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: pulse 1s infinite calc(1s / 5 * #{$i});
      }
    }
  }
  &.custom {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  &.loading-link {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 2;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    background-color: var(--color-light-black);
  }
  &.loading-link &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }
  &.loading-link &__dot {
    flex: 0 0 10px;
    vertical-align: unset;
  }
  &.loading-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    z-index: 2;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    background-color: var(--color-light-black);
  }
  &.loading-btn &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }
  &.loading-btn &__dot {
    flex: 0 0 10px;
    vertical-align: unset;
  }
}
