@import "../../styles/media-mixin";

.winners-horizontal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  &__container {
    position: relative;
    animation: slideInRight 0.5s both 0.5s;
  }
  &__item {
    padding-right: 5px;
    padding-left: 15px;
    @include media(L) {
      padding-left: 5px;
    }
    .slick-active & {
      animation: tada 1s both 1s;
    }
  }
  &__close-btn {
    position: absolute;
    top: 0;
    right: 5px;
    padding: 3px;
    font-size: 10px;
    color: var(--color-light-grey);
    transition: color 0.2s linear;
    @include media(L) {
      right: 0;
      color: #fff;
    }
  }
  .slick-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(200%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
