.game-grid {
  &__action {
    margin-top: 20px;
    text-align: center;
  }
  &__button {
    padding: 12px 35px;
  }
  &__preloader {
    padding-top: 10px;
  }
}
