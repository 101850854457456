@import "~react-select/dist/react-select.min.css";

.custom-select-field {
  .Select-control {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 0;
    border-color: var(--color-light-grey);
    background-color: var(--color-grey);
  }
  .Select-arrow-zone {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 100%;
    padding-left: 5px;
  }
  .Select-arrow {
    border-width: 7px 7px 1.5px;
    vertical-align: bottom;
  }
  .Select-menu {
    max-height: none;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    overflow-y: hidden;
  }
  .Select-menu-outer {
    max-height: none;
    border-top: none;
    border-color: var(--color-light-grey);
    background-color: #1f1f1f;
    box-shadow: none;
    font-size: 13px;
    line-height: 16px;
  }
  .Select-option {
    background-color: transparent;
  }
  .Select-value-label {
    font-size: 13px;
    line-height: 16px;
  }
  .Select-input {
    max-width: 100%;
    height: 38px;
  }
  .Select-input input {
    max-width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
  }
  .Select-control .Select-input:focus {
    background: transparent;
  }
  .Select-option.is-selected {
    color: #fff;
  }
  .Select-option:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .Select-input > input {
    max-width: 80%;
    line-height: 22px;
  }
  .Select-noresults {
    font-size: 13px;
  }
  .Select-placeholder {
    font-size: 13px;
    color: var(--color-light-grey);
  }
  &.is-disabled > .Select-control {
    border-color: #423211;
    background-color: #141414;
  }
  &.has-danger .Select-control {
    border-color: var(--color-warning);
    box-shadow: 0 0 4px #ff3b3b, inset 0 0 4px #ff3b3b;
  }
  &.is-open > .Select-control,
  &.is-focused:not(.is-open) > .Select-control {
    border-color: var(--color-light-grey);
    background-color: var(--color-grey);
    box-shadow: none;
  }
  &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: var(--color-light-grey);
  }
  &.is-disabled .Select-placeholder {
    color: #5e481c;
  }
  &.has-value.is-disabled.Select--single > .Select-control .Select-value .Select-value-label {
    color: #5e481c;
  }
  &.Select--single > .Select-control .Select-value {
    padding-right: 30px;
    line-height: 38px;
  }
  &.is-open > .Select-control .Select-arrow {
    border-width: 0 7px 7px;
  }
}
