.btn {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  transition: none;
  &:focus {
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    opacity: 0.8;
  }
  &-accent {
    border: 0;
    background: var(--color-accent);
    color: #2c2c2c;
    &:hover {
      background: #ebc120;
      color: #2c2c2c;
    }
  }
  &-outline-accent {
    border-width: 2px;
    border-color: var(--color-accent);
    color: var(--color-accent);
    &:hover {
      border-color: #ebc120;
      background: #ebc120;
      color: #2c2c2c;
    }
  }
}
