@import "../../../styles/media-mixin";

.checkbox {
  position: relative;
  display: block;
  margin-bottom: 0;
  padding-left: 30px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-light-grey);
  cursor: pointer;
  user-select: none;
  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-accent);
    font-size: 8px;
    color: transparent;
  }
  .has-danger &__icon {
    border-color: var(--color-danger);
  }
  input:checked ~ .checkbox__icon {
    background: var(--color-accent);
    color: var(--color-grey);
  }
  input {
    position: absolute;
    opacity: 0;
  }
}
