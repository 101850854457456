.track-vertical {
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 4px;
  background-color: #4a4a4a;
}

.thumb-vertical {
  right: 0;
  width: 6px !important;
  border-radius: 4px;
  background-color: #4a4a4a;
}

.track-horizontal {
  display: none;
}
