@import "../../styles/media-mixin";

.game-item {
  margin-bottom: 45px;
  @include media(XL) {
    height: 150px;
  }
  .btn {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: normal;
  }
  .btn,
  button {
    display: none;
  }
  &__wrapper {
    position: relative;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    @include media(L) {
      &:hover .game-item__overlay {
        display: flex;
      }
      &:hover .btn,
      &:hover button {
        display: inline-block;
      }
      &:hover + .game-item__name {
        color: #fff;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
  }
  &__label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
    min-width: 90px;
    height: 23px;
    padding-right: 8px;
    padding-left: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 13px;
    color: #fff;
    &.new {
      background-color: #06f;
    }
    &.jackpot {
      background-color: #ff4f4f;
    }
    &.gameof {
      background-color: #00a31a;
    }
  }
  &__jackpot {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, #000 61.6%);
    text-align: center;
    font-size: 21px;
    color: #ff4f4f;
  }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    border: 3px solid var(--color-accent);
    background-color: rgba(0, 0, 0, 0.8);
    &.open {
      display: flex;
    }
    &.open .btn,
    &.open button {
      display: inline-block;
    }
  }
  &__name {
    margin-top: 5px;
    text-align: center;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
    color: #4f4d4d;
    transition: 0.3s;
  }
  &__favorite {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: var(--color-light-grey);
    @include media(S) {
      right: 10px;
      bottom: 10px;
      font-size: 20px;
    }
    @include media(XL) {
      right: 7px;
      bottom: 7px;
    }
    &.active {
      color: var(--color-accent);
    }
  }
}
